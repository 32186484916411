<template>
    <div>
        <HeaderXS></HeaderXS>
        <div class="container">
            <div class="row">
                <div class="col-8 offset-2 text-center">

                <h1>Algemeine Geschäftsbedingungen</h1>
            </div>
            <div class="col-12">
                <p>
                    <strong>§1 Gegenstand der Geschäftsbedingungen</strong><br>
                    Gegenstand der nachfolgenden Bedingungen ist die Fahrzeugaufbereitung, insbesondere das Reinigen und Pflegen an Kraftfahrzeugen aller Art, soweit dies gesetzlich zulässig ist. Die angebotenen Leistungen werden ausschließlich zu den jeweils gültigen Allgemeinen Geschäftsbedingungen von waschprofis.de erbracht.
                </p>
                <p>
                    <strong>§2 Terminvereinbarungen/<br>Auftragserteilung</strong><br>
                    <ol type="a">
                        <li>Terminvereinbarungen werden grundsätzlich in gegenseitigem Einverständnis beider Vertragsparteien getroffen und können online unter <a href="https://www.waschprofis.de">www.waschprofis.de</a>, telefonisch unter 0351-28579285 oder während der Öffnungszeiten persönlich vor Ort, Dohnaer Straße 310 in 01257 Dresden gebucht werden.</li>
                        <li>Eilaufträge müssen vom Auftraggeber als solche deklariert werden. Dieser Service ist unverbindlich und richtet sich nach der Auftragslage der Autoaufbereitung.</li>
                        <li>Terminvereinbarungen stellen gleichzeitig Auftragserteilungen durch den Auftraggeber dar und werden im rechtlichen Sinne auch als solche angesehen.<br><br>
                    Unabhängig davon hat der Kunde unmittelbar vor dem Beginn der Fahrzeugaufbereitung eine schriftliche Auftragsbestätigung/Auftrag zu unterschreiben oder einen schriftlichen Auftrag per Fax oder E-mail zu übersenden.</li>
                    </ol>
                </p>
                <p>
                    <strong>§3 Nichteinhaltung einer Terminvereinbarung</strong><br>
                    <ol type="a">
                        <li>Terminvereinbarungen behalten ihre Gültigkeit bis zu dem vereinbarten Termin, wenn sie nicht mindestens 24 Stunden vor dem Erfüllungsdatum von einer Seite der Vertragsparteien schriftlich (es genügt auch eine E Mail an storno@waschprofis.de) aufgekündigt werden.</li>
                        <li>Falls Termine vom Auftraggeber nicht spätestens 24 Stunden vor dem Termin gemäß § 3 a aufgekündigt werden, kann waschprofis.de eine Verdienstausfallpauschale von € 80,– erheben.<br><br>
                        Der Nachweis eines geringeren Schadens ist damit nicht ausgeschlossen.<br><br></li>
                        <li>Wenn aufgrund höherer Gewalt und/oder behördlichen Anordnungen eine Terminvereinbarung nicht eingehalten werden kann, entfällt für beide Vertragsparteien eine dadurch bedingte Schadenersatz- oder Erfüllungspflicht.</li>
                        <li>Schadenersatzansprüche ergeben sich wohl aus § 3 a + b, nicht aber aus § 3 c.</li>
                    </ol>
                </p>
                <p>
                    <strong>§4 Zahlungsbedingungen / Zahlungsvereinbarungen</strong><br>
                    <ol type="a">
                        <li>Barzahlung bei Abholung des Fahrzeuges</li>
                        <li>Firmen die einen schriftlichen Auftrag erteilt haben zahlen nach Rechnungserhalt per Überweisung oder Barzahlung ohne Abzug.</li>
                    </ol>
                </p>
                <p>
                    <strong>§5 Reklamationen</strong><br>
                    <ol type="a">
                        <li>Reklamationen können nur unverzüglich nach erbrachter Leistung geltend gemacht werden.</li>
                        <li>Bei einer berechtigten Reklamation, hat waschprofis.de das Recht zur zweimaligen Nachbesserung. Bei erfolglosen Nachbesserungsversuchen wird von waschprofis.de ein angemessener Preisnachlass gewährt.</li>
                        <li>Ein Anspruch auf Nachbesserung kann nur geltend gemacht werden, wenn der Fehler eindeutig bei der Fahrzeugaufbereitung von waschprofis.de liegt und kein Vorsatz oder grobe Fahrlässigkeit seitens des Kunden vorliegt.</li>
                    </ol>
                </p>
                <p>
                    <strong>§6 Haftung und Garantie</strong><br>
                    <ol type="a">
                        <li>Die Firma waschprofis.de übernimmt die volle Haftung für Schäden, die durch grobe Fahrlässigkeit oder Vorsatz seitens waschprofis.de bei Arbeiten am Kraftfahrzeug verursacht wurden waschprofis.de  übernimmt keine Haftung für die im Fahrzeug verbliebenen Gegenstände jeder Art.</li>
                        <li>Die Haftung für Schäden, die vor der Fahrzeugaufbereitung an dem betreffenden Fahrzeug schon vorhanden waren und durch die Fahrzeugaufbereitung an diesem Fahrzeug vergrößert wurden, wird nicht übernommen.</li>
                        <li>waschprofis.de übernimmt keine Garantie für den Erfolg der von ihr am Kraftfahrzeug ausgeführten Arbeiten, wenn der Zustand des Kraftfahrzeugs schon im Vornherein an einem Erfolg zweifeln lässt. Über diesen Umstand wird der Kunde vor Beginn der Arbeiten unterrichtet. Für möglicherweise auftretende Verfärbungen, Flecken etc. übernimmt waschprofis.de keine Haftung.</li>
                        <li>Motor- und Motorraumwäsche wird nur auf Kundenwunsch durchgeführt. Hierbei wird keine Gewährleistung seitens waschprofis.de für Schäden oder Folgeschäden übernommen.</li>
                        <li>Für Schäden durch Hagel, Sturm, Erdbeben, Überschwemmung, Feuer, Leitungswasser, Diebstahl des Fahrzeuges oder des Inhaltes des Fahrzeuges kann keine Haftung übernommen werden.</li>
                        <li>Bei beschädigtem Autozubehör wie z.B. schadhaften Felgen, Antennen, Außenspiegeln, nicht fachgerecht angebrachtem Zubehör usw., welche durch waschprofis.de beschädigt oder zerstört wird, besteht kein Anspruch auf Schadensanspruch.</li>
                        <li>Sollten sich im Fahrzeug feuchtigkeitsempfindliche Einbauten wie Sound Anlagen, Mobilfunkgeräte oder ähnliches befinden behalten wir uns eine Einschränkung der Reinigungsleistung vor.</li>
                    </ol>
                </p>
                <p>
                    <strong>§7 Formalitäten und schriftliche Absicherung</strong><br>
                    <ol type="a">
                        <li>Bevor die Arbeit am Kraftfahrzeug des Kunden aufgenommen werden kann, ist vom Kunden das Formular Auftragsbestätigung gegenzuzeichnen. Diese dienen der rechtlichen Absicherung von waschprofis.de.</li>
                        <li>Mit der Unterzeichnung des Formulars, bestätigt der Kunde die Richtigkeit der Angaben und akzeptiert diese Allgemeinen Geschäftsbedingungen bzw. die auf der Auftragsbestätigung vermerkten außerordentlichen Vereinbarungen.</li>
                    </ol>
                </p>
                <p>
                    <strong>§8 Preise/Pauschalpreise</strong><br>
                    <ol type="a">
                        <li>Die Preise richten sich im Allgemeinen nach dem Zustand des Kraftfahrzeugs vor Beginn der Reinigung/Pflege.</li>
                        <li>Preisangaben auf Informationsunterlagen von waschprofis.de dienen lediglich zur Orientierung und können je nach Fall stark von den Orientierungspreisen abweichen.</li>
                        <li>Die endgültigen Preise der zu erbringenden Leistungen werden unmittelbar vor Beginn der Arbeiten festgelegt und auf der Auftragsbestätigung vermerkt.</li>
                        <li>Bei starken Verschmutzungen wie z.B. Tierhaare, Fäkalien, Farben, usw. kann ein Aufpreis geltend gemacht werden, unabhängig von vorausgegangenen Preisvereinbarungen.</li>
                        <li>Mehr- oder Sonderleistungen werden dem Kunden in Form unserer Stundensätze für Aufbereitung in Rechnung gestellt.</li>
                    </ol>
                </p>
                <p>
                    <strong>§9 Geschenkgutscheine</strong><br>
                    <ol type="a">
                        <li>Geschenkgutscheine können nur für unsere Serviceleistungen eingelöst werden, nicht jedoch für den Kauf von weiteren Geschenkgutscheinen.</li>
                        <li>Reicht der Wert eines Geschenkgutscheines für die Inanspruchnahme einer Serviceleistung nicht aus, kann die Differenz mit den unter §4 angegebenen Zahlungsmöglichkeiten ausgeglichen werden.</li>
                        <li>Der Wert oder Restwert eines Gutscheines wird weder ausgezahlt noch verzinst. Der Restwert kann nur mit einer nachfolgenden Serviceleistung verrechnet werden.</li>
                        <li>Die Gutscheine sind nicht personengebunden und können auf andere Kunden übertragen werden.</li>
                    </ol>
                </p>
                <p>
                    <strong>§10 Gültigkeit der Allgemeinen Geschäftsbedingungen</strong><br>
                    <ol type="a">
                        <li>Diese Allgemeinen Geschäftsbedingungen haben Gültigkeit soweit keine anderweitige Vereinbarung zwischen den Geschäftsparteien getroffen wurde.</li>
                        <li>Vereinbarungen, die von den hier aufgeführten Bedingungen abweichen, sind schriftlich zu verfassen.</li>
                        <li>Anderweitige Vereinbarungen, die einen oder mehrere Teile der Geschäftsbedingungen betreffen, haben keinen Einfluss auf die Gültigkeit der übrigen Bedingungen.</li>
                    </ol>
                </p>
                <p>
                    <strong>§11 Fotos</strong><br>
                    Zur internen Absicherung fertigt Waschprofis.de vor der Fahrzeugaufbereitung Bildmaterial von dem zu Reinigenden Fahrzeug an. Dieses dient ausschließlich der Aufnahme eventueller Schäden am Fahrzeug und ist nicht für die Öffentlichkeit bestimmt.
                </p>
                <p>
                    <strong>§12 Salvatorische Klausel</strong><br>
                    Sollten eine oder mehrere Bestimmungen oder Regelungen dieser AGB unwirksam oder lückenhaft sein, so werden die anderen hiervon nicht berührt. An die Stelle der unwirksamen oder lückenhaften Bestimmung oder Regelung soll eine Regelung treten, so wie es beide Parteien bei der Auftragserteilung beabsichtigt haben oder diese den gesetzlichen Bestimmungen am nächsten kommt. Änderungen sind vorbehalten.<br><br>
                    Für alle Streitigkeiten ist Kulmbach als Gerichtsstand vereinbart.
                    <br><br><br>
                    Stand 08.03.2021<br><br>
                    Änderungen und Irrtümer sind vorbehalten.
                </p>
                </div>
            </div>
        </div>
        <FooterXS></FooterXS>
    </div>
</template>
<script>

    import HeaderXS from "@/components/HeaderXS.vue";
    import FooterXS from "@/components/FooterXS.vue";

    export default {
        name: 'Terms',
        components: {
            HeaderXS,
            FooterXS,
        },
    }
</script>
<style lang="scss" scoped>
    h1{
        font-weight:400;
        font-size: 18px;
        letter-spacing: 2.1px;
        text-transform: uppercase;
        margin-bottom: 30px;
    }

    p{
        font-size: 17px;
        letter-spacing: 0.42px;
    }


</styles>